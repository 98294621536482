






























import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';

import Eye from '@/assets/icons/Eye.vue';
import EyeSlash from '@/assets/icons/EyeSlash.vue';

import
{
  STATUS_REDACTION_CORRECTED_STRING,
  STATUS_REDACTION_REJECTED_STRING,
  STATUS_REDACTION_PENDENT_STRING,
  STATUS_REDACTION_CHECKING_STRING,
} from '../../constants/StatusRedaction';

const COLOR_EYE = 'var(--neutralcolor-low-dark)';

const COMPONENT_EYE_REDACTION_OPEN = 'Eye';
const COMPONENT_EYE_SLASH_REDACTION_CLOSED = 'EyeSlash';

@Component({
  components: {
    Eye,
    EyeSlash,
  },
})
export default class ItemRedaction extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) statusRedaction!: string;
  @Prop({ required: true }) redactionID!: string;
  @Prop({ default: null }) time!: string;

  private colorEye = COLOR_EYE;

  get setComponentEyeStatusRedaction() {
    return this.statusRedaction === STATUS_REDACTION_CORRECTED_STRING || this.statusRedaction === STATUS_REDACTION_REJECTED_STRING
      ? COMPONENT_EYE_REDACTION_OPEN
      : COMPONENT_EYE_SLASH_REDACTION_CLOSED;
  }

  get isPendentOrCheckingRedaction() {
    return this.statusRedaction === STATUS_REDACTION_PENDENT_STRING
      || this.statusRedaction === STATUS_REDACTION_CHECKING_STRING;
  }

  goToRedaction() {
    if (this.isPendentOrCheckingRedaction) return;

    if (this.statusRedaction === STATUS_REDACTION_REJECTED_STRING) {
      this.$emit('emitModalRedactionRejected');
      return;
    }

    this.$router.push({
      name: 'CorrectionRedaction',
      params: {
        redactionId: this.redactionID,
      },
    });
  }

  formatTimeRedaction(time: number) {
    return moment.utc(time * 1000).format('HH:mm:ss');
  }
}
