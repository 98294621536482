export const STATUS_REDACTION_PENDENT_STRING = 'PENDENT';
export const STATUS_REDACTION_PENDENT_NUMBER = 0;

export const STATUS_REDACTION_CORRECTED_STRING = 'CORRECTED';
export const STATUS_REDACTION_CORRECTED_NUMBER = 1;

export const STATUS_REDACTION_DELETED_STRING = 'DELETED';
export const STATUS_REDACTION_DELETED_NUMBER = 2;

export const STATUS_REDACTION_REJECTED_STRING = 'REJECTED';
export const STATUS_REDACTION_REJECTED_NUMBER = 3;

export const STATUS_REDACTION_CHECKING_STRING = 'CHECKING';
export const STATUS_REDACTION_CHECKING_NUMBER = 4;
